import React, { useState } from 'react';
import './App.css';
import { TextField, IconButton, Collapse, Fade, CircularProgress, Zoom } from '@mui/material';
import { Button } from '@mui/material-next';
import { Send, RestartAlt } from '@mui/icons-material';

export default () => {
    const [question, setQuestion] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [state, setState] = useState<'question' | 'loading' | 'answer'>('question');

    const onClick = () => {
        setState("loading");
        setAnswer("");
        console.log(JSON.stringify({
            question
        }))
        fetch("https://hook.eu1.make.com/ouipher7w0ol6lrc7rrpsqmyax2xk29e", {
            method: "POST",
            body: JSON.stringify({
                question
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.text()).then(res => {
            setState("answer");
            if (res === "Accepted") return setAnswer("Nie potrafię odpowiedzieć na takie pytanie.");
            setAnswer(res);
        }).catch(err => {
            console.error(err);
            setAnswer("Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.");
            setState("answer");
        });
    };

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 30
        }}
    >
        <Collapse
            in={state === 'question'}
            style={{
                width: window.innerWidth > 1000 ? "85%" : "100%",
            }}
        >
            <h1 style={{ textAlign: "center" }}>Zadaj pytanie</h1>
            <TextField
                variant="outlined"
                value={question}
                onChange={e => setQuestion(e.target.value)}
                autoFocus
                fullWidth
                InputProps={{
                    endAdornment: <IconButton onClick={onClick}><Send /></IconButton>
                }}
                onKeyDown={e => e.key === 'Enter' && onClick()}
            />
            <p><i>Dane tylko do września 2021 roku. Może generować fałszywe odpowiedzi w przypadku braku wiedzy na dany temat.</i></p>
        </Collapse>
        <Fade in={state === 'loading'}>
            <CircularProgress />
        </Fade>
        <Zoom in={state === 'answer'} style={{ transitionDelay: "500ms" }}>
            <h1 style={{ fontSize: 90, color: answer.startsWith("P") || answer.endsWith("P") ? "green" : "red" }}>{answer.startsWith("P") || answer.endsWith("P") ? "Prawda" : "Fałsz"}</h1>
        </Zoom>
        <Zoom in={state === 'answer' && !(answer.startsWith("P") || answer.endsWith("P"))} style={{ transitionDelay: "800ms" }}>
            <h2 style={{ color: "black" }}>{answer}</h2>
        </Zoom>
        <Button
            variant="filled"
            onMouseDown={() => {
                setState("question");
                setQuestion("");
            }}
            style={{
                position: "absolute",
                bottom: 16,
                right: 16
            }}
            color="tertiary"
            disabled={state !== 'answer'}
            startIcon={<RestartAlt />}
        >
            Zadaj kolejne pytanie
        </Button>
        <footer
            style={{
                position: "absolute",
                bottom: 16,
                left: 16
            }}
        >
            &copy; Skarżyński
        </footer>
    </div>;
};
